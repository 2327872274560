import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid2,
  Card,
  CardContent,
  Typography,
  CircularProgress
} from '@mui/material';
import { Link, useLoaderData, useLocation } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

function ListingAnalytics() {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const { variation } = useLoaderData();
  const location = useLocation()
  const data = location.state;
  const site = data.site || 'www.sootoday.com';
  const author = data.author;
  const contentType = data.contentType;
  const categoryName = data.categoryName
  const [startDate, setStartDate] = useState(dayjs(data.startDate) || dayjs().subtract(31, 'day'));
  const [endDate, setEndDate] = useState(dayjs(data.endDate) || dayjs().subtract(1, 'day'));

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 3,
      renderCell: (params) => (
        <Link 
          to={`/platform-design/analytics/details`}
          state={{
            storyId: params.row.storyId,
            title: params.value,
            category: params.row.category,
            site: site,
            startDate: `${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}`,
            endDate: `${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`
          }}
        >
          {params.value}
        </Link>
      )
    },
    { field: 'views', headerName: 'Views', flex: 1 }
  ];

  useEffect(() => {
    // Fetch interest groups when an audience is selected
    setLoading(true)
    if (variation === "author") {
      fetch(`/api/listings-analytics?authorName=${author}&site=${site}&startDate=${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}&endDate=${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`)
        .then((res) => res.json())
        .then((data) => {
          console.log('Author Lookup response:', data);
          setResults(data);
          setLoading(false)
        })
        .catch((error) => console.error('Error fetching author:', error));
    } else if (variation === "content-type") {
      fetch(`/api/listings-analytics?contentType=${contentType}&site=${site}&startDate=${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}&endDate=${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`)
        .then((res) => res.json())
        .then((data) => {
          console.log('Author Lookup response:', data);
          setResults(data);
          setLoading(false)
        })
        .catch((error) => console.error('Error fetching author:', error));
    } else if (variation === "category") {
      fetch(`/api/listings-analytics?categoryName=${categoryName}&site=${site}&startDate=${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}&endDate=${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`)
        .then((res) => res.json())
        .then((data) => {
          console.log('Author Lookup response:', data);
          setResults(data);
          setLoading(false)
        })
        .catch((error) => console.error('Error fetching author:', error));
    }
  }, [author, startDate, endDate, site, variation, contentType, categoryName]);

  return (
    <Container maxWidth="lg">
      <Grid2
        container
        spacing={3}
      >
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>&nbsp;</Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newDate) => {
              setStartDate(newDate)
              console.log(startDate)
            }}
            sx={{ width: "100%" }}
            maxDate={dayjs().subtract(2, "day")}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newDate) => {
              setEndDate(newDate)
              console.log(startDate)
            }} 
            sx={{ width: "100%" }}
            maxDate={dayjs().subtract(1, "day")}
            />
        </Grid2>
      </Grid2>
      {loading ? (
        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        results ? (
          <>
            { author && (<Typography variant="h4" style={{ marginTop: "20px" }}>Author Report: {author}</Typography>) }
            { categoryName && (<Typography variant="h4" style={{ marginTop: "20px" }}>Category Report: {categoryName}</Typography>) }
            { contentType && (<Typography variant="h4" style={{ marginTop: "20px" }}>ContentType Report: {contentType}</Typography>) }
            <Grid2 container spacing={3} marginTop={3}>
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption">Pageviews</Typography>
                    <Typography variant="h4">{Number(results.totalViews).toLocaleString()}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption">Story Count</Typography>
                    <Typography variant="h4">{results.uniqueCount}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption">Stories per Day</Typography>
                    <Typography variant="h4">{Math.round(results.storiesPerDay * 100) / 100}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption">Pages per story</Typography>
                    <Typography variant="h4">{Math.round(results.pagesPerStory * 100) / 100}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
            <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={results.authorResults} columns={columns} />
          </>
        ) : (
          <></>
        ))}
    </Container>
  );
}

export default ListingAnalytics;
