import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid2,
  CircularProgress,
  Paper,
  Autocomplete,
  TextField
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

function EmailAudiences() {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [audiences, setAudiences] = useState([])
  const [audience, setAudience] = useState()
  const [emailLoading, setEmailLoading] = useState(true)
  const [startDate, setStartDate] = useState(dayjs().subtract(14, 'day'));
  const [endDate, setEndDate] = useState(dayjs().subtract(1, 'day'));

  const columns = [
    {
      field: 'name', headerName: 'Audience', flex: 2, renderCell: (params) => (
        <Link
          to={`/platform-design/mailchimp-audiences/interests`}
          state={{
            audience: params.value,
          }}
        >
          {params.value}
        </Link>
      )
    },
    { field: 'date', headerName: 'Date', flex: 2 },
    { field: 'members', headerName: 'Subscribers', flex: 1.5 },
    { field: 'unsubscribers', headerName: 'Unsubscribers', flex: 1.5 }
  ];

  useEffect(() => {
    if (audience) {
      setLoading(true)
      fetch(`/api/mailchimp-audiences?audience=${audience}`)
        .then((res) => res.json())
        .then((data) => {
          setResults(data.audiences)
          setLoading(false)
        })
    } else {
      setLoading(true)
      fetch(`/api/audiences`)
        .then((res) => res.json())
        .then((data) => {
          let audienceResults = []
          data.forEach((item => audienceResults.push(item.name)))
          setAudiences(audienceResults || [])
          setEmailLoading(false)
        })
        .catch((error) => console.error('Error fetching audiences:', error));
      fetch(`/api/mailchimp-audiences`)
        .then((res) => res.json())
        .then((data) => {
          setResults(data.audiences)
          setLoading(false)
        })
    }
  }, [audience, startDate, endDate]);

  return (
    <Container maxWidth="lg">
      <Grid2
        container
        spacing={3}
      >
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
          <Autocomplete
            disablePortal
            renderInput={(params) => <TextField {...params} label="Audience" />}
            options={audiences}
            onInputChange={(e, value, reason) => { if (reason === "selectOption") { setAudience(value) } }}
            loading={emailLoading}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newDate) => {
              setStartDate(newDate)
            }}
            sx={{ width: "100%" }}
            maxDate={dayjs().subtract(2, "day")}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <DatePicker
            fullWidth
            label="End Date"
            value={endDate}
            onChange={(newDate) => {
              setEndDate(newDate)
            }}
            sx={{ width: "100%" }}
            maxDate={dayjs().subtract(1, "day")}
          />
        </Grid2>
      </Grid2>
      {loading ? (
        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        results ? (
          <>
            <Paper elevation={5} sx={{ marginTop: '20px', height: '400px' }}>
              <DataGrid rows={results} columns={columns} />
            </Paper>
          </>
        ) : (
          <></>
        )
      )}
    </Container >
  );
}

export default EmailAudiences;
