import { Alert, Box, Button, Container, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

function InterstitialBuilder() {
    const [timeIn, setTimeIn] = useState('10');
    const [timeOut, setTimeOut] = useState('15');
    const [format, setFormat] = useState('PNG');
    const [width, setWidth] = useState('320');
    const [height, setHeight] = useState('480');
    const [output, setOutput] = useState(null);
    const [alert, setAlert] = useState();

    const handleSubmit = (event) => {
        event.preventDefault();
        const generatedOutput = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Interstitial Ad</title>
    <style>
        /* Modal styles */
        #interstitialAd {
            display: none; /* Hidden by default */
            position: fixed;
            z-index: 1000;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(0,0,0,0.5); /* Black background with transparency */
            align-items: center;
            justify-content: center;
        }
        /* Modal Content */
        #adContent {
            position: relative;
            margin: auto;
            padding: 0;
            width: ${width}px;
            height: ${height}px;
            background-color: #fff;
        }
        /* Close button */
        .close {
            position: absolute;
            top: -48px;
            right: 0px;
            color: #aaa;
            font-size: 36px;
            font-weight: bold;
        }
        .close:hover,
        .close:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
        /* Countdown Timer */
        #countdown {
            position: absolute;
            bottom: -15px; /* Adjust to be below the progress bar */
            left: 50%;
            transform: translateX(-50%);
            color: #000;
            font-size: 12px;
        }
        /* Progress Bar */
        #progressBar {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: #ddd;
        }
        #progress {
            height: 100%;
            width: 100%;
            background-color: #4caf50;
        }
    </style>
</head>
<body>

<!-- The Modal -->
<div id="interstitialAd">
    <div id="adContent">
        <span class="close" onclick="closeAd()">&times;</span>
        <a href="#" onclick="openDestination()">
            <img src="%%FILE:${format}1%%" alt="Ad Image" width="${width}" height="${height}">
        </a>
        <div id="progressBar">
            <div id="progress"></div>
        </div>
        <div id="countdown">This ad will close in ${timeOut} seconds</div>
    </div>
</div>

<script>
    var redirectUrl = null;
    var redirectTarget = "_self";
    var adShown = false;
    var adCompleted = false;
    var countdownTimer;
    var countdownSeconds = ${timeOut};
    var destinationLink = "%%CLICK_URL_UNESC%%%%DEST_URL%%";

    // Show the modal after ${timeIn} seconds
    setTimeout(function() {
        showAd();
    }, ${timeIn} * 1000);

    // Show the ad
    function showAd() {
        if (!adShown && !adCompleted) {
            adShown = true;
            resizeParentContainer();
            document.getElementById('interstitialAd').style.display = 'flex';
            fireImpressionMacro();
            startCountdown();
            setTimeout(closeAd, ${timeOut} * 1000); // Automatically close after ${timeOut} seconds
        }
    }

    // Close the ad
    function closeAd() {
        document.getElementById('interstitialAd').style.display = 'none';
        resetParentContainer();
        if (redirectUrl) {
            if (redirectTarget === "_blank") {
                window.parent.open(redirectUrl, "_blank");
            } else {
                window.parent.location.href = redirectUrl;
            }
        }
        adCompleted = true; // Mark the ad as completed
        clearInterval(countdownTimer); // Stop the countdown timer
    }

    // Fire impression macro
    function fireImpressionMacro() {
        var img = new Image();
        img.src = '%%VIEW_URL_UNESC%%';
    }

    // Resize the parent container
    function resizeParentContainer() {
        if (window.parent && window.parent.document) {
            var parentContainer = window.parent.document.getElementById(window.name);
            if (parentContainer) {
                parentContainer.style.cssText = "position: fixed; top: 0; left: 0; bottom: 0; width: 100%; height: 100%; margin: 0; z-index: 1050;";
            }
        }
    }

    // Reset the parent container
    function resetParentContainer() {
        if (window.parent && window.parent.document) {
            var parentContainer = window.parent.document.getElementById(window.name);
            if (parentContainer) {
                parentContainer.style.cssText = "";
            }
        }
    }

    // Start the countdown timer and progress bar
    function startCountdown() {
        var countdownElement = document.getElementById('countdown');
        var progressElement = document.getElementById('progress');
        countdownTimer = setInterval(function() {
            countdownSeconds--;
            countdownElement.textContent = 'This ad will close in ' + countdownSeconds + ' seconds';
            progressElement.style.width = (countdownSeconds / ${timeOut}) * 100 + '%';
            if (countdownSeconds <= 0) {
                clearInterval(countdownTimer);
            }
        }, 1000);
    }

    // Close the ad and open the destination on an ad click
    function openDestination() {
      window.open(destinationLink, "_blank");
      closeAd();
    }

    // Listen for link clicks on the parent page
    if (window.parent && window.parent.document) {
        window.parent.document.addEventListener('click', function(event) {
            if (adCompleted) {
                return; // If the ad has been completed, do nothing
            }
            var target = event.target;
            while (target && target !== window.parent.document) {
                if (target.tagName === 'A' && target.href) {
                    event.preventDefault();
                    redirectUrl = target.href;
                    redirectTarget = target.target || "_self"; // Remember the target attribute
                    showAd();
                    break;
                }
                target = target.parentNode;
            }
        }, true); // Use capture phase to ensure we catch the click event early
    }
</script>

</body>
</html>`;
        setOutput(generatedOutput);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(output);
        setAlert(true);
    };

    return (
        <Container maxWidth="lg">
            <FormControl fullWidth margin="normal">
                <TextField
                    id="timeIn"
                    label="Time In (in seconds)"
                    variant="outlined"
                    value={timeIn}
                    onChange={(e) => {
                        setTimeIn(e.target.value)
                    }} />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <TextField
                    id="timeIn"
                    label="Time Out (in seconds)"
                    variant="outlined"
                    value={timeOut}
                    onChange={(e) => {
                        setTimeOut(e.target.value)
                    }} />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <InputLabel id="format-label">Format</InputLabel>
                <Select
                    labelId="format-label"
                    value={format}
                    label="Format"
                    onChange={(e) => {
                        setFormat(e.target.value);
                    }}
                >
                    <MenuItem value="JPG">
                        JPG
                    </MenuItem>
                    <MenuItem value="PNG">
                        PNG
                    </MenuItem>
                    <MenuItem value="GIF">
                        GIF
                    </MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
                <TextField
                    id="width"
                    label="Width (in pixels)"
                    variant="outlined"
                    value={width}
                    onChange={(e) => {
                        setWidth(e.target.value)
                    }} />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <TextField
                    id="height"
                    label="Height (in pixels)"
                    variant="outlined"
                    value={height}
                    onChange={(e) => {
                        setHeight(e.target.value)
                    }} />
            </FormControl>
            <Button onClick={handleSubmit} variant='contained'>Generate Tag</Button>
            {output && (
                <Box>
                    <Typography variant="h5" mt={3}>Output:</Typography>
                    {alert && (
                        <Alert severity='success'>Tag copied to Clipboard</Alert>
                    )}
                    <Button onClick={copyToClipboard}>Copy to Clipboard</Button>
                    <pre><code>{output}</code></pre>
                </Box>
            )}
        </Container>
    );
}

export default InterstitialBuilder;