import React from 'react';
import { Container, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import NewspaperIcon from '@mui/icons-material/Newspaper';

const Home = () => {
  return (
    <Container maxWidth="md">
      <Typography>Welcome to the Village Media Toolbox. This is a custom-built platform to provide tools that simplify the everyday tasks we take on as an organization.</Typography>
      <Typography sx={{ marginTop: "10px"}}>All tools are custom built to ensure they match Village Media's business needs, and are scaleable to be used by a variety of departments across the Village Media ecosystem.</Typography>
      <Typography sx={{ marginTop: "10px"}}>To start using tis platform, choose your department using the menu on and choose the option that fits your needs.</Typography>
      <Typography variant="h4" sx={{ marginTop: "20px"}}>Upcoming features</Typography>
      <List>
        <ListItem>
          <ListItemAvatar>
            <NewspaperIcon />
          </ListItemAvatar>
          <ListItemText 
            primary="Improved data visualization"
            secondary="Allow editors to dive into their content and visualize trends and other forms of data"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <NewspaperIcon />
          </ListItemAvatar>
          <ListItemText 
            primary="Cardinal direction visualization"
            secondary="An improvement of an existing News Consumer insights feature that shows opportunities for growth and optimization for each site"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <NewspaperIcon />
          </ListItemAvatar>
          <ListItemText 
            primary="Improved benchmarking"
            secondary="Identify underperforming content to suggest improvement for better visibility"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <NewspaperIcon />
          </ListItemAvatar>
          <ListItemText 
            primary="Editorial content segments"
            secondary="An analysis of how many gallery views a story produces, as well as obituary reporting, home page reporting and system page reporting"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <NewspaperIcon />
          </ListItemAvatar>
          <ListItemText 
            primary="Active accounts view"
            secondary="A dashboard that displays how many accounts have been activate on a given site"
          />
        </ListItem>
      </List>
    </Container>
  );
};

export default Home;