import { Alert, Button } from '@mui/material';
import React, { useState } from 'react';

function Widget({ groups, secondaryGroups, type, headline, message, publicKey, logo, callToAction, preview, formPlacement }) {

    const [alert, setAlert] = useState();
    const copyToClipboard = (adCode) => {
        navigator.clipboard.writeText(adCode);
        setAlert(true);
    };

    if (type === "Inline Widget") {
        // Construct the widget HTML as a string
        const widgetMarkup = `
            <style>
                daily-cta .daily-con, .daily-inline-cta .daily-con {max-width: 645px; margin: 0 auto;}
                .daily-cta.theme-secondary-bg {color: #111; margin: 15px -15px -15px; padding: 20px 15px;}
                .daily-con .btn-dark {color: #fff; background-color: #000; border-color: #000;}
                @media screen and (min-width: 601px) {
                    .daily-cta {
                        margin: 15px -15px -15px;
                        padding-top: 20px;
                        padding-bottom: 25px;
                        border-radius: 0;
                        border-width: 0px;
                    }
                    .daily-cta .daily-con,
                    .daily-inline-cta .daily-con {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: start;
                        -ms-flex-align: start;
                        align-items: start;
                        align-items: flex-start;
                    }
                    .daily-cta .btn-dark {padding-left: 1.5em;padding-right: 1.5em;}
                }
            </style>
            <script src="https://www.google.com/recaptcha/api.js" async defer></script><script>
                function onSubmit${formPlacement}(token) {
                    document.getElementById("vm-local-form-${formPlacement}").submit();
                }
            </script>
            <script>
            document.addEventListener("DOMContentLoaded", function () {
                /**
                 * Handle newsletter subscription checkboxes on signup pages
                 */
                // select all additional newsletters on the page and loop through them
                document.querySelectorAll('.js-vm-email-checkbox').forEach(function (newsletter) {
                    var inputs = newsletter.querySelectorAll('input');
                    var checkbox = newsletter.querySelector('input[type="checkbox"]');

                    // make sure all inputs have backup attrs
                    inputs.forEach(function (input) {
                        input.setAttribute('data-name', input.getAttribute('name'));

                        if (checkbox.checked) {
                            input.setAttribute('name', input.getAttribute('data-name'));
                        } else {
                            input.setAttribute('name', 'ignoreMe');
                        }
                    });

                    // select checkbox within that newsletter and wait for check event
                    newsletter.querySelector('input[type="checkbox"]').addEventListener('change', function (e) {
                        // change attrs on all inputs when checked/unchecked
                        inputs.forEach(function (input) {
                            if (checkbox.checked) {
                                input.setAttribute('name', input.getAttribute('data-name'));
                            } else {
                                input.setAttribute('data-name', input.getAttribute('name'));
                                input.setAttribute('name', 'ignoreMe');
                            }
                        });
                    });
                });
            });
            </script>
            <div class="card card-body daily-card" style="background:none">
            <div class="daily-inline-cta">
                <form action="/account/mailinglist" class="daily-con validate" id="vm-local-form-${formPlacement}" method="post" novalidate="novalidate">
                <input name="__RequestVerificationToken" type="hidden" />
                <img alt="The Stir" class="daily-logo" id="" src="${logo};w=140" style="margin-right:30px;" />
                <div>
                    <h2 class="title mt-0 mb-1">${headline}</h2>
                    <p class="mt-0">${message}</p>
                    <div class="input-group">
                    <label class="sr-only" for="share-email">Email</label>
                    <input aria-required="true" class="form-control" data-val="true" data-val-email="Email is invalid." data-val-required="Email is required." name="EMAIL" placeholder="you@example.com" required="" type="email" />
                    <span class="input-group-btn">
                        <input class="button g-recaptcha btn btn-dark" data-callback="onSubmit${formPlacement}" data-sitekey="${publicKey}" name="subscribe" type="submit" value="${callToAction}" />
                    </span>
                    </div>
                    ${groups
                .map(
                    (group, index) => `
                        <div class="checkbox" style="display:none;">
                        <label>
                            <input checked="true" data-val="true" data-val-required="The IsSubscriber field is required." id="Groups_${index}__IsSubscriber" name="Groups[${index}].IsSubscriber" type="checkbox" value="true" />
                            <input name="Groups[${index}].IsSubscriber" type="hidden" value="false" />
                            ${group.name}
                        </label>
                        <input id="Groups_${index}__Name" name="Groups[${index}].Name" type="hidden" value="${group.name}" />
                        <input id="Groups_${index}__Id" name="Groups[${index}].Id" type="hidden" value="${group.id}" />
                        <input name="Invisible" type="hidden" value="true" />
                        </div>
                    `
                )
                .join('')}
                    ${secondaryGroups
                .map(
                    (group, index) => `
                        <div class="checkbox mt-2 js-vm-email-checkbox">
                        <label>
                            <input data-val="false" data-val-required="The IsSubscriber field is required." id="Groups_${index + 10}__IsSubscriber" name="Groups[${index + 10}].IsSubscriber" type="checkbox" value="false" />
                            <input name="Groups[${index + 10}].IsSubscriber" type="hidden" value="false" />
                            ${group.message}
                        </label>
                        <input id="Groups_${index + 10}__Name" name="Groups[${index + 10}].Name" type="hidden" value="${group.name}" />
                        <input id="Groups_${index + 10}__Id" name="Groups[${index + 10}].Id" type="hidden" value="${group.id}" />
                        </div>
                    `
                )
                .join('')}
                </div>
                </form>
            </div>
            </div>
        `;
        return preview ? (
            <div dangerouslySetInnerHTML={{ __html: widgetMarkup }} />
        ) : (
            <>
                {alert && (
                    <Alert severity='success'>Tag copied to Clipboard</Alert>
                )}
                <Button onClick={() => copyToClipboard(widgetMarkup)}>Copy to Clipboard</Button>
                <pre>
                    <code>
                        {widgetMarkup}
                    </code>
                </pre>
            </>
        )
    } else if (type === "Landing Page") {
        // Construct the widget HTML as a string
        const widgetMarkup = `
        <script src="https://www.google.com/recaptcha/api.js" async defer></script><script>
            function onSubmit${formPlacement}(token) {
                document.getElementById("vm-local-form-${formPlacement}").submit();
            }
        </script>
        <form action="/account/mailinglist" class="validate" id="vm-local-form-${formPlacement}" method="post" name="vm-embedded-form" novalidate="novalidate"><input name="__RequestVerificationToken" type="hidden" />
        <div class="input-group"><label class="sr-only" for="share-email">Email</label> <input aria-required="true" class="form-control input-lg" data-val="true" data-val-email="Email is invalid." data-val-required="Email is required." id="mce-EMAIL" name="EMAIL" placeholder="you@example.com" required="" type="email" /> <span class="input-group-btn"> <input class="button g-recaptcha btn btn-dark btn-lg" data-callback="onSubmit${formPlacement}" data-sitekey="${publicKey}" id="vm-embedded-subscribe" name="subscribe" type="submit" value="${callToAction}" /> </span></div>
                ${groups
                .map(
                    (group, index) => `
                     <div class="checkbox" style="display:none;">
                     <label>
                         <input checked="true" data-val="true" data-val-required="The IsSubscriber field is required." id="Groups_${index}__IsSubscriber" name="Groups[${index}].IsSubscriber" type="checkbox" value="true" />
                         <input name="Groups[${index}].IsSubscriber" type="hidden" value="false" />
                         ${group.name}
                     </label>
                     <input id="Groups_${index}__Name" name="Groups[${index}].Name" type="hidden" value="${group.name}" />
                     <input id="Groups_${index}__Id" name="Groups[${index}].Id" type="hidden" value="${group.id}" />
                     <input name="Invisible" type="hidden" value="true" />
                     </div>
                 `
                )
                .join('')}
                 ${secondaryGroups
                .map(
                    (group, index) => `
                     <div class="checkbox mt-2 js-vm-email-checkbox">
                     <label>
                         <input data-val="false" data-val-required="The IsSubscriber field is required." id="Groups_${index + 10}__IsSubscriber" name="Groups[${index + 10}].IsSubscriber" type="checkbox" value="false" />
                         <input name="Groups[${index + 10}].IsSubscriber" type="hidden" value="false" />
                         ${group.message}
                     </label>
                     <input id="Groups_${index + 10}__Name" name="Groups[${index + 10}].Name" type="hidden" value="${group.name}" />
                     <input id="Groups_${index + 10}__Id" name="Groups[${index + 10}].Id" type="hidden" value="${group.id}" />
                     </div>
                 `
                )
                .join('')}
                </form>
     `;
        return preview ? (
            <div dangerouslySetInnerHTML={{ __html: widgetMarkup }} />
        ) : (
            <>

                {alert && (
                    <Alert severity='success'>Tag copied to Clipboard</Alert>
                )}
                <Button onClick={() => copyToClipboard(widgetMarkup)}>Copy to Clipboard</Button>
                <pre>
                    <code>
                        {widgetMarkup}
                    </code>
                </pre>
            </>
        )
    }
}

export default Widget
