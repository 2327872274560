import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
    Grid2,
    CircularProgress,
    Autocomplete,
    TextField,
    Card,
    CardContent,
    Typography,
    Button,
    Paper,
    Snackbar
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useUser } from '@clerk/clerk-react';
import generatePDF from 'react-to-pdf';
import DownloadIcon from '@mui/icons-material/Download';

function DisplayReport() {
    const [orders, setOrders] = useState(null);
    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(false);
    const [advertiserLoading, setAdvertiserLoading] = useState(true)
    const [startDate, setStartDate] = useState(dayjs().subtract(31, 'day'));
    const [endDate, setEndDate] = useState(dayjs().subtract(1, 'day'));
    const [advertisers, setAdvertisers] = useState([]);
    const [advertiser, setAdvertiser] = useState();
    const [ordersLoading, setOrdersLoading] = useState(false)
    const [results, setResults] = useState();
    const { user } = useUser();
    const parentSite = user?.publicMetadata?.parentSite || "1";

    const targetRef = useRef();

    useEffect(() => {
        // Fetch audiences on component mount
        if (advertiser && !order) {
            setLoading(true)
            setOrdersLoading(true)
            fetch(`/api/gam-order-lookup?company=${advertiser.id}`)
                .then((res) => res.json())
                .then((data) => {
                    setOrders(data.orders || [])
                    setOrdersLoading(false)
                })
                .catch((error) => console.error('Error fetching audiences:', error));
            fetch(`/api/gam-reporting?company=${advertiser.id}&startDate=${startDate}&endDate=${endDate}`)
                .then((res) => res.json())
                .then((data) => {
                    setResults(data)
                    setLoading(false)
                })
        } else if (order) {
            setLoading(true)
            fetch(`/api/gam-reporting?order=${order}&startDate=${startDate}&endDate=${endDate}`)
                .then((res) => res.json())
                .then((data) => {
                    setResults(data)
                    setLoading(false)
                })
        } else {
            fetch(`/api/gam-company-lookup`)
                .then((res) => res.json())
                .then((data) => {
                    setAdvertisers(data.advertisers || [])
                    setAdvertiserLoading(false)
                })
                .catch((error) => console.error('Error fetching audiences:', error));
        }
    }, [advertiser, startDate, endDate, parentSite, order]);

    return (
        <>
            <Container ref={targetRef} maxWidth="lg">
                <Grid2
                    sx={{ paddingTop: "20px" }}
                    container
                    spacing={3}
                >
                    <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                        <Paper>
                            <Autocomplete
                                disablePortal
                                renderInput={(params) => <TextField {...params}
                                    label="Advertiser" />}
                                options={advertisers}

                                filterOptions={(options, { inputValue }) =>
                                    inputValue.length < 2 ? [] : options.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase())) // Display only 100 options after 2 characters
                                }
                                loading={advertiserLoading}
                                noOptionsText={"No results found. Please type the advertiser name as it appears in GAM."}
                                onChange={(e, value, reason) => {
                                    if (reason === "selectOption" || reason === "clear") {
                                        console.log(e)
                                        setAdvertiser(value)
                                    }
                                }}
                            />
                        </Paper>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                        <Paper>
                            <DatePicker
                                sx={{ width: "100%"}}
                                label="Start Date"
                                value={startDate}
                                onChange={(newDate) => {
                                    setStartDate(newDate)
                                    console.log(startDate)
                                }}
                                maxDate={dayjs().subtract(2, "day")}
                            />
                        </Paper>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                        <Paper>
                            <DatePicker
                                sx={{ width: "100%"}}
                                label="End Date"
                                value={endDate}
                                onChange={(newDate) => {
                                    setEndDate(newDate)
                                    console.log(startDate)
                                }}
                                maxDate={dayjs().subtract(1, "day")} />
                        </Paper>
                    </Grid2>
                    {orders && (
                        <Grid2 size={{ xs: 12, sm: 12, md: 12 }}>
                            <Paper>
                                <Autocomplete
                                    disablePortal
                                    renderInput={(params) => <TextField {...params}
                                        label="Order" />}
                                    options={orders}
                                    loading={ordersLoading}
                                    noOptionsText={"No results found. Please type the order name as it appears in GAM."}
                                    onChange={(e, value, reason) => {
                                        if (reason === "selectOption" || reason === "clear") {
                                            console.log(`Order: ${value.label} (${value.id})`)
                                            setOrder(value.id)
                                        }
                                    }}
                                />
                            </Paper>
                        </Grid2>
                    )}
                </Grid2>
                {loading ? (
                    <>
                        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
                    </>
                ) : (
                    results ? (
                        <>
                            <Grid2
                                container
                                spacing={3}
                                sx={{ marginTop: "20px" }}
                            >
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }} >
                                    <Card variant="elevation" raised={true} sx={Number(results.results.takeover.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                        <CardContent>
                                            <Typography variant="caption">Takeover Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.takeover.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.takeover.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.takeover.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.takeover.clicks) / Number(results.results.takeover.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>

                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }} sx={Number(results.results.tallBlock.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                    <Card variant="elevation" raised={true}>
                                        <CardContent>
                                            <Typography variant="caption">Tall Block Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.tallBlock.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.tallBlock.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.tallBlock.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.tallBlock.clicks) / Number(results.results.tallBlock.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }} sx={Number(results.results.bigBox.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                    <Card variant="elevation" raised={true}>
                                        <CardContent>
                                            <Typography variant="caption">Big Box Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.bigBox.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.bigBox.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.bigBox.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.bigBox.clicks) / Number(results.results.bigBox.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="elevation" raised={true} sx={Number(results.results.superSquare.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                        <CardContent>
                                            <Typography variant="caption">Super Square Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.superSquare.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.superSquare.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.superSquare.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.superSquare.clicks) / Number(results.results.superSquare.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }} sx={Number(results.results.inlineBillboard.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                    <Card variant="elevation" raised={true}>
                                        <CardContent>
                                            <Typography variant="caption">Inline Billboard Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.inlineBillboard.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.inlineBillboard.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.inlineBillboard.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.inlineBillboard.clicks) / Number(results.results.inlineBillboard.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="elevation" raised={true} sx={Number(results.results.inlineText.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                        <CardContent>
                                            <Typography variant="caption">Inline Text Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.inlineText.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.inlineText.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.inlineText.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.inlineText.clicks) / Number(results.results.inlineText.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="elevation" raised={true} sx={Number(results.results.video.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                        <CardContent>
                                            <Typography variant="caption">Video Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.video.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.video.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.video.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.video.clicks) / Number(results.results.video.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }} sx={Number(results.results.interstitial.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                    <Card variant="elevation" raised={true}>
                                        <CardContent>
                                            <Typography variant="caption">Interstitial Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.interstitial.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.interstitial.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.interstitial.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.interstitial.clicks) / Number(results.results.interstitial.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="elevation" raised={true} sx={Number(results.results.newsletters.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                        <CardContent>
                                            <Typography variant="caption">Newsletter Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.newsletters.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.newsletters.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.newsletters.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.newsletters.clicks) / Number(results.results.newsletters.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="elevation" raised={true} sx={Number(results.results.mobileBanner.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                        <CardContent>
                                            <Typography variant="caption">Mobile Banner Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.mobileBanner.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.mobileBanner.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.mobileBanner.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.mobileBanner.clicks) / Number(results.results.mobileBanner.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="elevation" raised={true} sx={Number(results.results.mobileBanner.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                        <CardContent>
                                            <Typography variant="caption">Mobile Footer Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.mobileFooter.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.mobileFooter.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.mobileFooter.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.mobileFooter.clicks) / Number(results.results.mobileFooter.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="elevation" raised={true} sx={Number(results.results.tabletBanner.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                        <CardContent>
                                            <Typography variant="caption">Tablet Banner Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.tabletBanner.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.tabletBanner.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.tabletBanner.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.tabletBanner.clicks) / Number(results.results.tabletBanner.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="elevation" raised={true} sx={Number(results.results.alert.impressions) > 0 ? {} : { opacity: "0.5" }}>
                                        <CardContent>
                                            <Typography variant="caption">Alert Banner Impressions</Typography>
                                            <Typography variant="h4">{Number(results.results.alert.impressions).toLocaleString()}</Typography>
                                            <Typography variant="caption">Clicks</Typography>
                                            <Typography variant="h4">{Number(results.results.alert.clicks).toLocaleString()}</Typography>
                                            {Number(results.results.alert.impressions) > 0 && (
                                                <>
                                                    <Typography variant="caption">CTR</Typography>
                                                    <Typography variant="h4">{Math.round((Number(results.results.alert.clicks) / Number(results.results.alert.impressions)) * 10000) / 100}%</Typography>
                                                </>)}
                                        </CardContent>
                                    </Card>
                                </Grid2>
                            </Grid2>
                        </>
                    ) : (
                        <></>
                    )
                )}
            </Container >
            <Snackbar
                open={true}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Button
                    sx={{ position: "fixed", bottom: "20", right: "20" }}
                    onClick={() => {
                        generatePDF(targetRef, { filename: `display-report-${advertiser.label}-${startDate.format("MM/DD/YYYY")}-${endDate.format("MM/DD/YYYY")}.pdf` })
                    }}
                >
                    <DownloadIcon sx={{ fontSize: "2rem" }} />
                </Button>
            </Snackbar>
        </>
    );
}

export default DisplayReport;
