import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { PageContainer } from '@toolpad/core/PageContainer';
import { SignedIn, SignedOut, SignInButton, UserButton } from '@clerk/clerk-react';
import { Button, Typography } from '@mui/material';

export default function Layout() {

  return (
      <DashboardLayout
        slots={{
          toolbarAccount: UserButton
        }}
      >
        <PageContainer>
          <SignedOut>
            <Typography>Sign in to view Villager Tools</Typography>
            <SignInButton>
              <Button variant="contained">Sign In</Button>
            </SignInButton>
          </SignedOut>
          <SignedIn>
              <Outlet />
          </SignedIn>
        </PageContainer>
      </DashboardLayout>
  );
}
