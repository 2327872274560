import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Layout from './layouts/dashboard';
import EmailWidgets from './pages/mailchimp-widgets';
import AdUnits from './pages/gam-builder';
import InterstitialBuilder from './pages/interstitial-builder';
import { GlobalStyles } from '@mui/material';
import BusinessDashboard from './pages/business-dashboard';
import Home from './pages/home';
import SignupForms from './pages/signup-forms';
import StoryDetails from './pages/details-analytics';
import SiteDashboard from './pages/site-breakdown';
import EmailDashboard from './pages/mailchimp-dashboard';
import { ClerkProvider } from '@clerk/clerk-react';
import ListingAnalytics from './pages/listing-analytics';
import DisplayReport from './pages/display-dashboard';
import NetworkDashboard from './pages/network-dashboard';
import Readers from './pages/reader-count';
import EmailAudiences from './pages/mailchimp-audiences';
import EmailInterests from './pages/mailchimp-interests';


const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    Component: App, // root layout route
    children: [
      {
        path: '/',
        Component: Layout,
        children: [
          {
            path: '',
            Component: Home
          },
          {
            path: '/platform-design/email-ads',
            Component: AdUnits
          },
          {
            path: '/client-services/interstitial-ads',
            Component: InterstitialBuilder
          },
          {
            path: '/client-services/business-dashboard',
            Component: BusinessDashboard
          },
          {
            path: '/platform-design/mailchimp-widgets',
            Component: EmailWidgets
          },
          {
            path: '/platform-design/signup-forms',
            Component: SignupForms
          },
          {
            path: '/platform-design/analytics/author',
            Component: ListingAnalytics,
            loader: () => ({ variation: "author" })
          },
          {
            path: '/platform-design/analytics/content-type',
            Component: ListingAnalytics,
            loader: () => ({ variation: "content-type" })
          },
          {
            path: '/platform-design/analytics/category',
            Component: ListingAnalytics,
            loader: () => ({ variation: "category" })
          },
          {
            path: '/platform-design/analytics/details',
            Component: StoryDetails
          },
          {
            path: '/platform-design/analytics',
            Component: SiteDashboard
          },
          {
            path: '/platform-design/network',
            Component: NetworkDashboard
          },
          {
            path: '/platform-design/mailchimp-dashboard',
            Component: EmailDashboard
          },
          {
            path: '/platform-design/readers',
            Component: Readers
          },
          {
            path: '/client-services/display-report',
            Component: DisplayReport
          },
          {
            path: '/platform-design/mailchimp-audiences',
            Component: EmailAudiences
          },
          {
            path: '/platform-design/mailchimp-audiences/interests',
            Component: EmailInterests
          },
          {
            path: '/business-dashboard',
            element: <Navigate to="/client-services/business-dashboard" />
          },
          {
            path: '/mailchimp-widgets',
            element: <Navigate to="/platform-design/mailchimp-widgets" />
          },
          {
            path: '/interstitial-ads',
            element: <Navigate to="/client-services/interstitial-ads" />
          },
          {
            path: '/email-ads',
            element: <Navigate to="/platform-design/email-ads" />
          },
          {
            path: '*', // catch-all wildcard
            element: <Navigate to="/" replace /> // redirect to home or any other desired route
          }
        ]
      }
    ]
  },
]);

const PUBLISHABLE_KEY = 'pk_live_Y2xlcmsudG9vbHMudmlsbGFnZW1lZGlhLmNhJA';

root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
      <GlobalStyles styles={{ pre: { whiteSpace: 'pre-wrap' } }} />
      <RouterProvider router={router} />
    </ClerkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
