import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid2,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Paper,
  Autocomplete,
  TextField
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

function EmailDashboard() {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([])
  const [email, setEmail] = useState()
  const [emailLoading, setEmailLoading] = useState(true)
  const [startDate, setStartDate] = useState(dayjs().subtract(14, 'day'));
  const [endDate, setEndDate] = useState(dayjs().subtract(1, 'day'));

  const columns = [
    { field: 'title', headerName: 'Email', flex: 2 },
    { field: 'emailDate', headerName: 'Date', flex: 2 },
    { field: 'sends', headerName: 'Sends', flex: 1.5 },
    { field: 'opens', headerName: 'Unique Opens', flex: 1.5 },
    {
      field: 'openRate',
      headerName: 'Open Rate',
      flex: 1.5,
    },
    { field: 'totalOpens', headerName: 'Total Opens', flex: 1.5 },
    { field: 'sessions', headerName: 'Sessions', flex: 1.5 },
    { field: 'sessionsRate', headerName: 'Sessions Rate', flex: 1.5 }
  ];

  useEffect(() => {
    if (email) {
      setLoading(true)
      fetch(`/api/mailchimp-campaigns?email=${email}&startDate=${startDate}&endDate=${endDate}`)
        .then((res) => res.json())
        .then((data) => {
          setResults(data)
          setLoading(false)
        })
        .catch((error) => console.error('Error fetching audiences:', error));
    } else {
      setLoading(true)
      fetch(`/api/mailchimp-campaigns?startDate=${startDate}&endDate=${endDate}`)
        .then((res) => res.json())
        .then((data) => {
          let emailResults = []
          data.uniqueEmails.forEach((item => emailResults.push(item.email)))
          setEmails(emailResults || [])
          setEmailLoading(false)
          setResults(data)
          setLoading(false)
        })
        .catch((error) => console.error('Error fetching audiences:', error));
    }

  }, [email, startDate, endDate]);

  return (
    <Container maxWidth="lg">
      <Grid2
        container
        spacing={3}
      >
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
          <Autocomplete
            disablePortal
            renderInput={(params) => <TextField {...params} label="Email" />}
            options={emails}
            onInputChange={(e, value, reason) => { if (reason === "selectOption") { setEmail(value) } }}
            loading={emailLoading}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newDate) => {
              setStartDate(newDate)
            }}
            sx={{ width: "100%" }}
            maxDate={dayjs().subtract(2, "day")}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <DatePicker
            fullWidth
            label="End Date"
            value={endDate}
            onChange={(newDate) => {
              setEndDate(newDate)
            }}
            sx={{ width: "100%" }}
            maxDate={dayjs().subtract(1, "day")}
          />
        </Grid2>
      </Grid2>
      {loading ? (
        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        results ? (
          <>
            <Grid2 container spacing={3} marginTop={3}>
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <Card variant="elevation" raised={true}>
                  <CardContent>
                    <Typography variant="caption">Total Sends</Typography>
                    <Typography variant="h4">{Number(results.totalSends).toLocaleString()}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <Card variant='elevation' raised={true}>
                  <CardContent>
                    <Typography variant="caption">Total Unique Opens</Typography>
                    <Typography variant="h4">{Number(results.totalUniqueOpens).toLocaleString()}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <Card variant="elevation" raised={true}>
                  <CardContent>
                    <Typography variant="caption">Total Opens</Typography>
                    <Typography variant="h4">{Number(results.totalOpens).toLocaleString()}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <Card variant="elevation" raised={true}>
                  <CardContent>
                    <Typography variant="caption">Total Sessions</Typography>
                    <Typography variant="h4">{Number(results.totalSessions).toLocaleString()}</Typography>
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
            <Paper elevation={5} sx={{ marginTop: '20px', height: '400px' }}>
              <DataGrid rows={results.results} columns={columns} />
            </Paper>
          </>
        ) : (
          <></>
        )
      )}
    </Container >
  );
}

export default EmailDashboard;
