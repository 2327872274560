import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Alert,
  Button
} from '@mui/material';


const ads = {
  "units": [
    {
      "id": "NET_Newsletter",
      "size": "600x155%7C600x450"
    },
    {
      "id": "NET_Newsletter_Mid",
      "size": "970x250"
    },
    {
      "id": "Trillium_TheT_Newsletter",
      "size": "600x155"
    },
    {
      "id": "Trillium_AM_Newsletter",
      "size": "600x155"
    },
    {
      "id": "Trillium_PM_Newsletter",
      "size": "600x155"
    },
    {
      "id": "NET_Email_Alert",
      "size": "636x34"
    },
    {
      "id": "NET_Spaces_Feature",
      "size": "600x300"
    }
  ],
  "placements": [
    {
      "placement": "1"
    },
    {
      "placement": "2"
    },
    {
      "placement": "3"
    },
    {
      "placement": "4"
    },
    {
      "placement": "5"
    },
    {
      "placement": "6"
    },
    {
      "placement": "7"
    },
  ]
}

function AdUnits() {
  const [adUnit, setAdUnit] = useState({
    site: 'www.sootoday.com',
    ad: 'NET_Newsletter',
    placement: 1,
    size: "970x250%7C600x155%7C600x450",
    service: "bundle",
    siteId: 0
  });

  const [sites, setSites] = useState([]);

  useEffect(() => {
    // Fetch audiences on component mount
    fetch('/api/sites')
      .then((res) => res.json())
      .then((data) => {
        setSites(data.results || []);
      })
      .catch((error) => console.error('Error fetching sites:', error));
  }, []);


  const [alert, setAlert] = useState();
  const [preview, setPreview] = useState();

  const gamAd = `  <a href="https://securepubads.g.doubleclick.net/gampad/jump?iu=%2F5198809%2F${adUnit.ad}&sz=${adUnit.size}&clkk=*|UNIQID|*_*|CAMPAIGN_UID|*_*|DATE:d/m/y|*&ptt=21&t=Site%3D${adUnit.site}%26email_pos%3D${adUnit.placement}&clkp=${adUnit.placement}&url=us8.campaign-archive.com%252F%253Fe%253D*|UNIQID|*%2526u%253D*|EMAIL_UID|*%2526id%253D*|CAMPAIGN_UID|*" target="_blank">
    <img src="https://securepubads.g.doubleclick.net/gampad/ad?iu=%2F5198809%2F${adUnit.ad}&sz=${adUnit.size}&clkk=*|UNIQID|*_*|CAMPAIGN_UID|*_*|DATE:d/m/y|*&ptt=21&t=Site%3D${adUnit.site}%26email_pos%3D${adUnit.placement}&clkp=${adUnit.placement}&url=us8.campaign-archive.com%252F%253Fe%253D*|UNIQID|*%2526u%253D*|EMAIL_UID|*%2526id%253D*|CAMPAIGN_UID|*" />
  </a>
  <br>
  <a href="https://securepubads.g.doubleclick.net/gampad/jump?iu=%2F5198809%2F${adUnit.ad}&sz=${adUnit.size}&clkk=*|UNIQID|*_*|CAMPAIGN_UID|*_*|DATE:d/m/y|*&ptt=21&t=Site%3D${adUnit.site}%26email_pos%3D${adUnit.placement}&clkp=${adUnit.placement}&url=us8.campaign-archive.com%252F%253Fe%253D*|UNIQID|*%2526u%253D*|EMAIL_UID|*%2526id%253D*|CAMPAIGN_UID|*&click_type=wta" >
    Why this ad?
  </a>
  `

  const bundleAd = `  <a href="https://bundle.villagemedia.ca/click/?uid=*|UNIQID|*&cid=*|CAMPAIGN_UID|*_*|DATE:mdy|*_${adUnit.placement}&f=https://${adUnit.site}" target="_blank">
    <img ${adUnit.ad !== "NET_Email_Alert" ? 'style="width:100%;" ' : 'style="max-height:34px!important;width:100%;" '}width="${adUnit.ad !== "NET_Email_Alert" ? '600' : '636'}" ${adUnit.ad !== "NET_Email_Alert" ? '' : 'height="34"'} src="https://bundle.villagemedia.ca/view/?iu=/5198809/${adUnit.ad}&sz=${adUnit.size}&t=site%3D${adUnit.site}&uid=*|UNIQID|*&cid=*|CAMPAIGN_UID|*_*|DATE:mdy|*_${adUnit.placement}&s=${adUnit.siteId}" />
  </a>`

  function getSiteIdByUrl(urlToFind) {
    const site = sites.find(site => site.url === urlToFind);
    return site ? site.foreign_keys.site_id : null;
  }

  const handleChange = (e) => {
    const site = e.site ? e.site : adUnit.site;
    const ad = e.ad ? e.ad : adUnit.ad;
    const placement = e.placement ? e.placement : adUnit.placement;
    const service = e.service ? e.service : adUnit.service;
    const siteId = getSiteIdByUrl("https://" + site);
    let size = "970x250";

    if (ad === "NET_Email_Alert") {
      size = "636x34";
    } else if (ad === "NET_Newsletter") {
      size = "600x155%7C600x450";
    } else if (["Trillium_TheT_Newsletter", "Trillium_AM_Newsletter", "Trillium_PM_Newsletter"].includes(ad)) {
      size = "600x155";
    } else if (ad === "NET_Spaces_Feature") {
      size = "600x300"
    }

    setAdUnit({ site, ad, placement, size, service, siteId });
    setPreview(false);
  }

  const copyToClipboard = (adCode) => {
    navigator.clipboard.writeText(adCode);
    setAlert(true);
  };

  const previewAd = () => {
    setPreview(true)
  }

  return (
    <Container maxWidth="lg">
      <Box component="form">
        <FormControl fullWidth margin="normal">
          <InputLabel id="service-label">Select Your Service</InputLabel>
          <Select
            labelId="service-label"
            id="service"
            label="Select Your Service"
            value={adUnit.service}
            onChange={(e) => handleChange({ service: e.target.value })}
          >
            <MenuItem value="bundle">Bundle</MenuItem>
            <MenuItem value="gam">GAM</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="site-label">Select Your Site</InputLabel>
          <Select
            labelId="site-label"
            id="site"
            label="Select Your Site"
            value={adUnit.site}
            onChange={(e) => handleChange({ site: e.target.value })}
          >
            {sites.map(site => (
              site.partner === "Village Media" &&
              <MenuItem key={site.foreign_keys.site_id} value={site.url.replace("https://", "")}>{site.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="ad-label">Select Your Ad Unit</InputLabel>
          <Select
            labelId="ad-label"
            id="ad"
            label="Select Your Ad Unit"
            value={adUnit.ad}
            onChange={(e) => handleChange({ ad: e.target.value })}
          >
            {ads.units.map(unit => (
              <MenuItem key={unit.id} value={unit.id}>{unit.id} ({unit.size})</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="placement-label">Select Your Ad Placement</InputLabel>
          <Select
            labelId="placement-label"
            id="placement"
            label="Select Your Ad Placement"
            value={adUnit.placement}
            onChange={(e) => handleChange({ placement: e.target.value })}
          >
            {ads.placements.map(placement => (
              <MenuItem key={placement.placement} value={placement.placement}>{placement.placement}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Typography variant="h5" mt={3}>Email Ad Tag:</Typography>
      {adUnit.service === "gam" && (
        <Box>
          {alert && (
            <Alert severity='success'>Tag copied to Clipboard</Alert>
          )}
          {preview && (
            <div dangerouslySetInnerHTML={{ __html: gamAd }} />
          )}
          <Button onClick={() => copyToClipboard(gamAd)}>Copy to Clipboard</Button>
          <Button onClick={() => previewAd()}>Preview Ad Unit</Button>
          <pre>
            <code>
              {gamAd}
            </code>
          </pre>
        </Box>
      )}
      {adUnit.service === "bundle" && (
        <Box>
          {alert && (
            <Alert severity='success'>Tag copied to Clipboard</Alert>
          )}

          <Button onClick={() => copyToClipboard(bundleAd)}>Copy to Clipboard</Button>
          <pre>
            <code>
              {bundleAd}
            </code>
          </pre>
        </Box>
      )}
    </Container>
  );
}

export default AdUnits;
