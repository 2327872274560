import React, { useEffect, useRef } from 'react';
import Widget from './widget';

function WidgetPreview(props) {
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <link rel="stylesheet" href="https://www.sootoday.com/cssb/template">
        </head>
        <body>
          <div id="body" class="container"></div>
        </body>
        </html>
      `);
      iframeDoc.close();

      // Render Widget into iframe
      const iframeRoot = iframeDoc.getElementById('body');
      if (iframeRoot) {
        import('react-dom').then((ReactDOM) => {
          ReactDOM.createRoot(iframeRoot).render(<Widget {...props} />);
        });
      }
    }
  }, [props]);

  return <iframe ref={iframeRef} style={{ width: '100%', height: '300px', border: 'none' }} title="Widget Preview" />;
}

export default WidgetPreview;
